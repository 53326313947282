import revive_payload_client_wX8PXQnLtt from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0EElwOpbI5 from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_E2oB3bcqcI from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_AC0y6M9SvN from "/opt/render/project/src/node_modules/.pnpm/@nuxtjs+supabase@1.4.6/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import feature_flags_plugin_PShWuBAc5t from "/opt/render/project/src/packages/core/client/plugins/feature-flags.plugin.ts";
import i18n_core_plugin_y2X8eQtDL3 from "/opt/render/project/src/packages/core/client/plugins/i18n-core.plugin.ts";
import payload_client_FcKxUMFC9h from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_4vCsuZ6RcF from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ShxfjMQE7X from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_loApepbD9f from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_zlvi6dcIsi from "/opt/render/project/src/apps/learner/node_modules/.cache/nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_OT589Eg3ed from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import motion_kGWjA4WmJS from "/opt/render/project/src/node_modules/.pnpm/@vueuse+motion@2.1.0_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.6.3_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import plugin_Y3Y1NZpjIc from "/opt/render/project/src/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.29.1_vite@6.0.11_@types+node@22.10.3_jiti@2.4.2_sas_74r2562oo53lvp4oduv64co2ey/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import custom_directives_plugin_cqvc0KzCti from "/opt/render/project/src/packages/ui-library/client/plugins/custom-directives.plugin.ts";
import form_plugin_client_W6uFRflGh7 from "/opt/render/project/src/packages/ui-library/client/plugins/form.plugin.client.ts";
import i18n_plugin_LHpKH4Gj5X from "/opt/render/project/src/packages/ui-library/client/plugins/i18n.plugin.ts";
import event_plugin_client_SySk9qkA9r from "/opt/render/project/src/packages/core/client/plugins/event.plugin.client.ts";
import hydration_plugin_bShEX4LLPo from "/opt/render/project/src/packages/core/client/plugins/hydration.plugin.ts";
import monitoring_plugin_client_SIX2iaqaCX from "/opt/render/project/src/packages/core/client/plugins/monitoring.plugin.client.ts";
import analytics_plugin_mXGu6nAVQD from "/opt/render/project/src/apps/learner/client/plugins/analytics.plugin.ts";
import i18n_plugin_4VM3oxkUhv from "/opt/render/project/src/apps/learner/client/plugins/i18n.plugin.ts";
export default [
  revive_payload_client_wX8PXQnLtt,
  unhead_0EElwOpbI5,
  router_E2oB3bcqcI,
  supabase_client_AC0y6M9SvN,
  feature_flags_plugin_PShWuBAc5t,
  i18n_core_plugin_y2X8eQtDL3,
  payload_client_FcKxUMFC9h,
  navigation_repaint_client_4vCsuZ6RcF,
  check_outdated_build_client_ShxfjMQE7X,
  chunk_reload_client_loApepbD9f,
  components_plugin_zlvi6dcIsi,
  prefetch_client_OT589Eg3ed,
  motion_kGWjA4WmJS,
  plugin_Y3Y1NZpjIc,
  custom_directives_plugin_cqvc0KzCti,
  form_plugin_client_W6uFRflGh7,
  i18n_plugin_LHpKH4Gj5X,
  event_plugin_client_SySk9qkA9r,
  hydration_plugin_bShEX4LLPo,
  monitoring_plugin_client_SIX2iaqaCX,
  analytics_plugin_mXGu6nAVQD,
  i18n_plugin_4VM3oxkUhv
]